<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-form inline>
        <el-form-item label="用户昵称">
          <el-input v-model="searchForm.nickname" placeholder="输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item label="用户编号">
          <el-input-number v-model="searchForm.uid"></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button @click="searchGo" type="primary">搜索</el-button>
          <el-button @click="searchReset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="list">
        <el-table-column prop="uid" label="编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="nickname" label="昵称" show-overflow-tooltip></el-table-column>
        <el-table-column label="头像" show-overflow-tooltip>
          <template #default="s">
            <img :src="s.row.avatar | tomedia" style="height: 3rem;width: 3rem;border-radius: .5rem" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="last_num" label="剩余盲返次数" show-overflow-tooltip></el-table-column>
        <el-table-column prop="used_num" label="已盲返次数" show-overflow-tooltip></el-table-column>
        <el-table-column prop="total_award" label="累计盲返金额" show-overflow-tooltip></el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>

const searchFormTpl = {
  nickname:"",
  uid:0,
  last_num:-1,
  used_num:-1,
  total_award:-1,
}

export default {
  components: {},
  name: "manage",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      searchForm:{...searchFormTpl}
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    pageChange(e) {
      this.page = e;
      this.load();
    },
    searchGo(){
      this.page = 1;
      this.load();
    },
    searchReset(){
      this.page = 1;
      this.searchForm = {...searchFormTpl};
      this.load();
    },
    load() {
      this.$u.api.shop.plugin.blindAward.userSearch({
        page: this.page,
        ...this.searchForm,
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>